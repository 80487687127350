<template>
  <div id="app">
    <van-nav-bar
      title="中瓷控股股份有限公司"
      fixed
      placeholder
      border
      @click-left="onMenuHandle"
    >
      <template #left>
        <van-icon
          v-if="!showMenu"
          name="wap-nav"
          size="25"
          color="#333"
          style="transform: scaleX(1.2)"
        />
        <van-icon v-if="showMenu" name="cross" size="25" color="#333" />
      </template>
      <template #title>
        <van-image
          width="90"
          height="25"
          style="display: none"
          :src="require('./assets/images/fake-logo-v2.png')"
        />
        <van-image
          width="90"
          height="25"
          style="display: block"
          :src="require('./assets/images/logo-v3.png')"
          @click="goPage()"
        />
      </template>
    </van-nav-bar>
    <router-view />
    <footer class="common-footer-wrap">
      <div class="font-gray-black font-14 font-700">中瓷控股</div>
      <div class="mg-t-10 font-gray font-12 font-comfortable">
        中瓷控股股份有限公司
      </div>
      <div class="font-gray font-12 font-comfortable">沪ICP备16006927号-1</div>
    </footer>
    <van-popup v-model="showMenu" position="top" closeable>
      <div class="menu-list">
        <van-collapse v-model="activeNames">
          <div class="menu-list-item" v-for="item in menus" :key="item.id">
            <van-collapse-item
              v-if="item.children && item.children.length > 0"
              title-class="menu-list-item-content"
              :title="item.title"
              :name="item.id"
            >
              <van-cell
                v-for="child in item.children"
                :key="child.id"
                :title="child.title"
                @click="goPage(child)"
              />
            </van-collapse-item>
            <van-cell
              v-else
              title-class="menu-list-item-content"
              :title="item.title"
              is-link
              :border="true"
              @click="goPage(item)"
            />
          </div>
        </van-collapse>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      menus: [],
      activeNames: [],
    };
  },
  methods: {
    async getMenu() {
      const resp = await this.$api.get("getmenu", {});
      if (resp.success) {
        // this.menus = resp.data.menus;
        this.menus = this.dealMenu(resp.data.menus);
      }
    },
    dealMenu(menu) {
      menu.forEach((item) => {
        return this.sortSubmenu(item.children)
      })
      return menu
    },
    // 子目录按标题长度重新排序
    sortSubmenu(item) {
      return item.sort(function(a, b) {
        return a.title.length - b.title.length;
      })
    },
    onMenuHandle() {
      this.showMenu = !this.showMenu;
    },
    goPage(menu) {
      let query = {};
      if (!menu) {
        this.$router.push({ path: "/", query }).catch(() => {});
        this.showMenu = false;
        return;
      }
      if (menu.parent_id) {
        query.parent_id = menu.parent_id;
        query.id = menu.id;
      }
      this.$router.push({ path: menu.link, query }).catch(() => {});
      this.showMenu = false;
    },
  },
  async mounted() {
    await this.getMenu();
  },
};
</script>

<style lang="less">
@import "./assets/style/base.less";
.van-nav-bar__content {
  border-bottom: 1px solid @mainBlue;
}
.van-nav-bar--fixed {
  z-index: 3000 !important;
}
.van-popup {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.94) !important;
  .van-collapse-item__content {
    padding: 0 16px;
  }

  .van-cell--clickable {
    background-color: transparent;
    padding: 6px 0;
    border-bottom: 1.5px solid #e8e8e8;
  }
  .van-collapse-item__content {
    .van-cell {
      background-color: transparent;
    }
  }
  .van-collapse-item__content {
    background: transparent;
  }
  .menu-list-item-content {
    line-height: 36px;
  }
  .van-icon-arrow {
    line-height: 36px;
  }
}
.mg-t-10 {
  margin-top: 10px;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-t-25 {
  margin-top: 25px;
}

.mg-l-5 {
  margin-left: 5px;
}

.font-black {
  color: #333333;
}

.font-gray-black {
  color: #666666;
}

.font-gray {
  color: #999999;
}

.font-blue {
  color: @mainBlue;
  font-weight: bold;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 14px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-700 {
  font-weight: 700;
}

.font-comfortable {
  line-height: 1.7;
}

.dis-flex {
  display: flex;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-x-center {
  justify-content: center;
}

.flex-x-between {
  justify-content: space-between;
}

.flex-x-evenly {
  justify-content: space-evenly;
}

.flex-x-end {
  justify-content: flex-end;
}

.flex-y-center {
  align-items: center;
}

.flex-shrink0 {
  flex-shrink: 0;
}

.flex-basis100 {
  flex-basis: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.menu-list {
  padding: 62px 24px 15px;

  .menu-list-item {
    .menu-list-item-content {
      font-size: 16px;
    }

    .van-collapse-item__title--expanded {
      .font-blue();

      .van-cell__right-icon:before {
        .font-blue();
      }
    }
  }
}

.content {
  position: relative;
  padding: 15px 10px;
  word-wrap: break-word !important;
  word-break: break-all !important;
  text-align: justify !important;
  box-shadow: 0 6px 12px 0px rgba(215 215 215);

  img {
    // display: block;
    max-width: 100% !important;
    height: auto !important;
  }
}

.bottom-bar {
  display: block;
  margin: 0;
  padding: 10px 0;
  font-size: 12px;
  line-height: 1;
  color: #333;
  border-top: 1px solid #f0f0f0;
}

.common-header-wrap {
  width: 100%;

  .header-img {
    display: block;
    width: 100%;
    height: auto;
  }

  .tab-list {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid @mainBlue;
    background-color: #ffffff;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    .tab-item {
      height: 100%;
      padding: 0 15px;
    }
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.common-footer-wrap {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background-color: #f0f0f0;
  border-top: 1px solid #e6e6e6;
}
</style>
