import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Services from './services'
import './vant'

const api = new Services({
  rootUri: '//www.china-holdings.cn/api/'
})
Vue.prototype.$api = api

Vue.config.productionTip = false

// 使用钩子函数对路由进行权限跳转
// router.beforeEach((to, from, next) => {
//   if (location.host.match(/localhost/i) == 'localhost') {
//     next()
//   } else {
//     if (location.host.match(/www\.ant-core\.com/) == 'www.ant-core.com') {
//       const checkSystem = () => {
//         const sUserAgent = navigator.userAgent.toLowerCase();
//         const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
//         const bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
//         const bIsMidp = sUserAgent.match(/midp/i) == 'midp';
//         const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
//         const bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
//         const bIsAndroid = sUserAgent.match(/android/i) == 'android';
//         const bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
//         const bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
      
//         if ( bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
//           return 'h5'
//         } else {
//           return 'pc'
//         }
//       }
      
//       const systemType = checkSystem()
//       if (systemType === 'h5') {
//         next()
//       } else if (systemType === 'pc') {
//         window.location.href = 'http://www.china-holdings.cn'
//       }
//     }
//   }
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
