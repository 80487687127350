<template>
  <div class="home">
    <van-swipe class="home-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="item in slide"
        :key="item.id"
        @click="goPage(item)"
      >
        <img class="slide-img" v-lazy="'//res.china-holdings.cn/' + item.img" />
      </van-swipe-item>
    </van-swipe>

    <van-collapse
      class="mg-t-10 news-list item-shadow"
      v-model="newsActive"
      v-if="article && article.enterprise && article.enterprise.length > 0"
    >
      <van-collapse-item name="1" title-class="news-collapse-item">
        <template #title>
          <span class="font-blue font-16">企业资讯<div class="down-arrow"></div></span>
        </template>
        <van-cell
          style="padding-left: 0; padding-right: 0"
          title-style="width: 100%"
          v-for="item in article.enterprise"
          :key="item.id"
          :to="'/article/detail/' + item.id"
        >
          <template #title>
            <div class="dis-flex flex-x-between">
              <span class="font-14 font-black van-ellipsis flex-basis100">{{
                item.title
              }}</span>
              <span class="font-12 font-gray-black flex-shrink0 mg-l-5">{{
                item.updated_at.split(" ")[0]
              }}</span>
            </div>
          </template>
        </van-cell>
        <van-cell
          @click="
            goPage({ link: '/article/list/enterprise?id=17&parent_id=6' })
          "
        >
          <template #title>
            <div class="dis-flex flex-x-center flex-y-center">
              <span class="font-black font-14">查看更多</span>
              <van-icon name="arrow" color="#333" />
            </div>
          </template>
        </van-cell>
      </van-collapse-item>
    </van-collapse>

    <van-collapse
      class="mg-t-10 news-lis item-shadowt"
      v-model="reportActive"
      v-if="article && article.news && article.news.length > 0"
    >
      <van-collapse-item name="1" title-class="news-collapse-item">
        <template #title>
          <span class="font-blue font-16">媒体报道</span>
        </template>
        <van-cell
          style="padding-left: 0; padding-right: 0"
          title-style="width: 100%"
          v-for="(item, index) in article.news"
          :key="item.id"
          :to="'/article/detail/' + item.id"
        >
          <template #title>
            <div class="dis-flex flex-x-between" v-if="index !== 0">
              <span class="font-14 font-black van-ellipsis flex-basis100">{{
                item.title
              }}</span>
              <span class="font-12 font-gray-black flex-shrink0 mg-l-5">{{
                item.updated_at.split(" ")[0]
              }}</span>
            </div>
            <van-card
              v-else
              class="news-card"
              :thumb="'//res.china-holdings.cn/' + item.img"
            >
              <template #title>
                <div class="dis-flex flex-dir-column flex-x-between">
                  <span class="font-14 font-black van-multi-ellipsis--l2">{{
                    item.title
                  }}</span>
                  <span class="font-12 font-gray-black">{{
                    item.updated_at.split(" ")[0]
                  }}</span>
                </div>
              </template>
            </van-card>
          </template>
        </van-cell>
        <van-cell
          @click="
            goPage({ link: '/article/list/enterprise?id=18&parent_id=6' })
          "
        >
          <template #title>
            <div class="dis-flex flex-x-center flex-y-center">
              <span class="font-black font-14">查看更多</span>
              <van-icon name="arrow" color="#333" />
            </div>
          </template>
        </van-cell>
      </van-collapse-item>
    </van-collapse>

    <div class="mg-t-10 item-shadow">
      <van-swipe indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in business"
          :key="index"
          @click="goPage(item)"
        >
          <img class="business-img" :src="item.img" />
        </van-swipe-item>
      </van-swipe>
      <van-cell>
        <template #title>
          <div class="dis-flex flex-x-center flex-y-center font-blue font-16 core-buss-title">
            核心业务
          </div>
        </template>
      </van-cell>
    </div>

    <div class="mg-t-10 state-wrap dis-flex flex-x-between flex-wrap item-shadow">
      <div class="state-img-wrap">
        <img
          class="state-img"
          :src="require('../assets/images/home-state-img1.jpg')"
          @click="goPage({ link: '/page/bigdata?parent_id=16&id=2' })"
          alt=""
        />
      </div>
      <div class="state-img-wrap">
        <img
          class="state-img"
          :src="require('../assets/images/home-state-img2-v2.jpg')"
          @click="goPage({ link: '/page/Traceability?id=28&parent_id=16' })"
          alt=""
        />
      </div>
      <div class="state-img-wrap">
        <img
          class="state-img"
          :src="require('../assets/images/home-state-img3.png')"
          @click="goPage({ link: '/page/zhongciyun?id=3&parent_id=16' })"
          alt=""
        />
      </div>
    </div>

    <van-cell class="qr-code-wrap">
      <template #title>
        <div class="dis-flex flex-x-evenly flex-y-center">
          <img
            class="qr-code-btn"
            v-for="(item, index) in qrcode"
            :key="index"
            :src="item.btn"
            alt=""
            @click="
              show = true;
              qrInfo = item;
            "
          />
        </div>
      </template>
    </van-cell>

    <!-- qrcode -->
    <van-overlay
      :show="show"
      @click="
        show = false;
        qrInfo = null;
      "
    >
      <div class="wrapper dis-flex flex-x-center flex-y-center">
        <img class="qr-code-img" :src="qrInfo && qrInfo.target" alt="" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  data() {
    return {
      slide: [],
      article: null,
      newsActive: [],
      reportActive: [],
      business: [
        // {
        //   img: require("../assets/images/home-business-img1.jpg"),
        //   link: "/page/credit?parent_id=16&id=29",
        // },
        // {
        //   img: require("../assets/images/home-business-img2.jpg"),
        //   link: "/page/zhongciyun?parent_id=16&id=3",
        // },
        // {
        //   img: require("../assets/images/home-business-img3.jpg"),
        //   link: "/page/yueci?parent_id=16&id=5",
        // },
        {
          img: require("../assets/images/home-business-img4-v2.jpg"),
          link: "/page/Traceability?parent_id=16&id=28",
        },
        {
          img: require("../assets/images/home-business-img5-v2.jpg"),
          link: "/page/bigdata?parent_id=16&id=2",
        },
      ],
      qrcode: [
        {
          btn: require("../assets/images/home-qr-btn1-v3.png"),
          target: require("../assets/images/home-qr-img1-v2.png"),
        },
        {
          btn: require("../assets/images/home-qr-btn2-v2.png"),
          target: require("../assets/images/home-qr-img2-v2.png"),
        },
      ],
      show: false,
      qrInfo: null,
    };
  },
  methods: {
    async getSlide() {
      const resp = await this.$api.get("getslide", {});
      if (resp.success) {
        this.slide = resp.data.slide.items;
      }
    },
    goPage(menu) {
      if (!menu) return;
      if (!menu.link) return;
      if (
        /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(
          menu.link
        )
      ) {
        window.location.href = menu.link;
      } else {
        this.$router.push(menu.link);
      }
    },

    async getArticle() {
      const resp = await this.$api.get("getreport", {});
      if (resp.success) {
        this.article = resp.data;
      }
    },
  },
  async mounted() {
    await this.getSlide();
    await this.getArticle();
  },
};
</script>

<style lang="less">
@import "../assets/style/base.less";
.home {
  .van-swipe__indicators {
    left: 17% !important;
    bottom: 30px !important;
  }
  .news-collapse-item {
    text-align: center;
    // padding-left: 15px;
    line-height: 30px !important;
  }
  .van-cell__right-icon {
    display: none;
  }
  .down-arrow {
    position: relative;
    left: 6px;
    top: -5px;
    border: solid #2d5ed2;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    animation: godown .5s 0s linear infinite;
  }
  
  .down-arrow::before {
    content: "";
    position: absolute;
    left: -3px;
    top: -3px;
    border: solid #2d5ed2;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    animation: fadein .5s 0s linear infinite;
  }
  .down-arrow::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    border: solid #2d5ed2;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    animation: fadeout .5s 0s linear infinite;
  }
  
  @keyframes godown {
    from {
    }
    to {
      left: 6px;
      top: -1px;
    }
  }
  @keyframes fadein {
    from {
      border-color: white;
    }
    to {
      border-color: #2d5ed2;
    }
  }
  @keyframes fadeout {
    from {
      border-color: #2d5ed2;
    }
    to {
      border-color: white;
    }
  }
}


.core-buss-title {
  line-height: 30px;
}
.news-list {
  .van-collapse-item__title--expanded {
    &:after {
      border-color: @mainBlue;
    }
  }

  .van-card {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;

    .van-card__thumb {
      width: 114px;
      height: 72px;

      img {
        border-radius: 0;
      }
    }

    .van-card__content {
      min-height: auto;
    }
  }
}
</style>

<style lang="less" scoped>
.item-shadow {
  box-shadow: 0 0 8px #cfcfcf;
}
.home {
  background-color: #f0f0f0;
}
.slide-img {
  display: block;
  width: 100%;
  height: auto;
}

.business-img {
  display: block;
  width: 100%;
  height: auto;
}

.state-wrap {
  .state-img-wrap {
    width: 48.5%;

    &:nth-child(3n-2) {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .state-img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.qr-code-wrap {
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: transparent;

  .qr-code-btn {
    height: 42px;
  }
}

.qr-code-img {
  width: 150px;
  height: auto;
  border-radius: 4px;
}
</style>
