import Axios from 'axios'
import qs from 'qs'

const DEFAULTS = {
  rootUri: '/api/',
  retry: 3,
  duration: 300
}

class Services {
  constructor (settings) {
    this.settings = {}
    Object.assign(this.settings, DEFAULTS, settings)
  }

  async get (method, params, retry) {
    const resp = await Axios.get(this.settings.rootUri + method, {
      params
    })
    if (resp.status !== 200) {
      if (retry-- > 1) {
        setTimeout(async () => {
          const respRetry = await this.get(method, params, retry)
          return respRetry
        }, this.settings.duration)
      }
    } else {
      return resp.data
    }
  }

  async post (method, params, retry) {
    if (typeof retry === 'undefined') {
      retry = this.settings.retry
    }
    params.method = method
    const resp = await Axios.post(this.settings.rootUri + params.method, qs.stringify(params))
    if (resp.status !== 200) {
      if (retry-- > 1) {
        setTimeout(async () => {
          const respRetry = await this.post(method, params, retry)
          return respRetry
        }, this.settings.duration)
      }
    } else {
      return resp.data
    }
  }
}

export default Services
