import Vue from 'vue'

import { NavBar, Icon, Popup, Swipe, SwipeItem, Lazyload, Grid, GridItem, List, Cell, PullRefresh, Collapse, CollapseItem, Image as VanImage, Overlay, Card } from 'vant'

Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(List)
Vue.use(Cell)
Vue.use(PullRefresh)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(VanImage)
Vue.use(Overlay)
Vue.use(Card)
