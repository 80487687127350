import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/article/list/:cname?',
    name: 'ArticleList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "articlelist" */ '../views/Article/List.vue')
  },
  {
    path: '/article/detail/:id',
    name: 'ArticleDetail',
    component: () => import(/* webpackChunkName: "articledetail" */ '../views/Article/Detail.vue')
  },
  {
    path: '/page/:name',
    name: 'PageDetail',
    component: () => import(/* webpackChunkName: "pagedetail" */ '../views/Page/Detail.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
